import React from 'react'
import { Grid, GridColumn, GridRow } from 'semantic-ui-react'
import ArticlePreview from './article-preview'

class ArticleList extends React.Component {

  render() {
    const posts = this.props.posts
    return (
      <Grid doubling>
        {
          posts.map(({ node }) => (
            <GridColumn key={node.slug} computer={5} tablet={8} mobile={16}>
              <ArticlePreview article={node}/>
            </GridColumn>
          ))
        }
      </Grid>
    )
  }
}

export default ArticleList